import React from 'react'
import '../Sidebar/Sidebar.css'

const Lineicons = () => {
  return (
    <div className="page-wrapper">
  {/*page-content-wrapper*/}
  <div className="page-content-wrapper">
    <div className="page-content">
      {/*breadcrumb*/}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">Icons</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item"><a href="javascript:;"><i className="bx bx-home-alt" /></a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Line Icons</li>
            </ol>
          </nav>
        </div>
        <div className="ms-auto">
          <div className="btn-group">
            <button type="button" className="btn btn-primary">Settings</button>
            <button type="button" className="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end"> <a className="dropdown-item" href="javascript:;">Action</a>
              <a className="dropdown-item" href="javascript:;">Another action</a>
              <a className="dropdown-item" href="javascript:;">Something else here</a>
              <div className="dropdown-divider" /> <a className="dropdown-item" href="javascript:;">Separated link</a>
            </div>
          </div>
        </div>
      </div>
      {/*end breadcrumb*/}
      <div className="card radius-15">
        <div className="card-body">
          <div className="card-title">
            <h4 className="mb-0">Line Icons</h4>
          </div>
          <hr />
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-5 g-3">
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-500px" />
                </div>
                <div className="ms-2"> <span>500px</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-adobe" />
                </div>
                <div className="ms-2"> <span>adobe</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-agenda" />
                </div>
                <div className="ms-2"> <span>agenda</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-airbnb" />
                </div>
                <div className="ms-2"> <span>airbnb</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-alarm" />
                </div>
                <div className="ms-2"> <span>alarm</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-alarm-clock" />
                </div>
                <div className="ms-2"> <span>alarm-clock</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-amazon" />
                </div>
                <div className="ms-2"> <span>amazon</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-amazon-original" />
                </div>
                <div className="ms-2"> <span>amazon-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-amazon-pay" />
                </div>
                <div className="ms-2"> <span>amazon-pay</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ambulance" />
                </div>
                <div className="ms-2"> <span>ambulance</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-amex" />
                </div>
                <div className="ms-2"> <span>amex</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-anchor" />
                </div>
                <div className="ms-2"> <span>anchor</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-android" />
                </div>
                <div className="ms-2"> <span>android</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-android-original" />
                </div>
                <div className="ms-2"> <span>android-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angellist" />
                </div>
                <div className="ms-2"> <span>angellist</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angle-double-down" />
                </div>
                <div className="ms-2"> <span>angle-double-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angle-double-left" />
                </div>
                <div className="ms-2"> <span>angle-double-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angle-double-right" />
                </div>
                <div className="ms-2"> <span>angle-double-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angle-double-up" />
                </div>
                <div className="ms-2"> <span>angle-double-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-angular" />
                </div>
                <div className="ms-2"> <span>angular</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-apartment" />
                </div>
                <div className="ms-2"> <span>apartment</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-app-store" />
                </div>
                <div className="ms-2"> <span>app-store</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-apple" />
                </div>
                <div className="ms-2"> <span>apple</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-apple-pay" />
                </div>
                <div className="ms-2"> <span>apple-pay</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-archive" />
                </div>
                <div className="ms-2"> <span>archive</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-down" />
                </div>
                <div className="ms-2"> <span>arrow-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-down-circle" />
                </div>
                <div className="ms-2"> <span>arrow-down-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-left" />
                </div>
                <div className="ms-2"> <span>arrow-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-left-circle" />
                </div>
                <div className="ms-2"> <span>arrow-left-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-right" />
                </div>
                <div className="ms-2"> <span>arrow-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-right-circle" />
                </div>
                <div className="ms-2"> <span>arrow-right-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-top-left" />
                </div>
                <div className="ms-2"> <span>arrow-top-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-top-right" />
                </div>
                <div className="ms-2"> <span>arrow-top-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-up" />
                </div>
                <div className="ms-2"> <span>arrow-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrow-up-circle" />
                </div>
                <div className="ms-2"> <span>arrow-up-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrows-horizontal" />
                </div>
                <div className="ms-2"> <span>arrows-horizontal</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-arrows-vertical" />
                </div>
                <div className="ms-2"> <span>arrows-vertical</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-atlassian" />
                </div>
                <div className="ms-2"> <span>atlassian</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-aws" />
                </div>
                <div className="ms-2"> <span>aws</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-backward" />
                </div>
                <div className="ms-2"> <span>backward</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-baloon" />
                </div>
                <div className="ms-2"> <span>baloon</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ban" />
                </div>
                <div className="ms-2"> <span>ban</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bar-chart" />
                </div>
                <div className="ms-2"> <span>bar-chart</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-basketball" />
                </div>
                <div className="ms-2"> <span>basketball</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-behance" />
                </div>
                <div className="ms-2"> <span>behance</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-behance-original" />
                </div>
                <div className="ms-2"> <span>behance-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bi-cycle" />
                </div>
                <div className="ms-2"> <span>bi-cycle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bitbucket" />
                </div>
                <div className="ms-2"> <span>bitbucket</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bitcoin" />
                </div>
                <div className="ms-2"> <span>bitcoin</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-blackboard" />
                </div>
                <div className="ms-2"> <span>blackboard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-blogger" />
                </div>
                <div className="ms-2"> <span>blogger</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bluetooth" />
                </div>
                <div className="ms-2"> <span>bluetooth</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bold" />
                </div>
                <div className="ms-2"> <span>bold</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bolt" />
                </div>
                <div className="ms-2"> <span>bolt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bolt-alt" />
                </div>
                <div className="ms-2"> <span>bolt-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-book" />
                </div>
                <div className="ms-2"> <span>book</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bookmark" />
                </div>
                <div className="ms-2"> <span>bookmark</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bookmark-alt" />
                </div>
                <div className="ms-2"> <span>bookmark-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bootstrap" />
                </div>
                <div className="ms-2"> <span>bootstrap</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bricks" />
                </div>
                <div className="ms-2"> <span>bricks</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bridge" />
                </div>
                <div className="ms-2"> <span>bridge</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-briefcase" />
                </div>
                <div className="ms-2"> <span>briefcase</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-brush" />
                </div>
                <div className="ms-2"> <span>brush</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-brush-alt" />
                </div>
                <div className="ms-2"> <span>brush-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bubble" />
                </div>
                <div className="ms-2"> <span>bubble</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bug" />
                </div>
                <div className="ms-2"> <span>bug</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bulb" />
                </div>
                <div className="ms-2"> <span>bulb</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bullhorn" />
                </div>
                <div className="ms-2"> <span>bullhorn</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-burger" />
                </div>
                <div className="ms-2"> <span>burger</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-bus" />
                </div>
                <div className="ms-2"> <span>bus</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cake" />
                </div>
                <div className="ms-2"> <span>cake</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-calculator" />
                </div>
                <div className="ms-2"> <span>calculator</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-calendar" />
                </div>
                <div className="ms-2"> <span>calendar</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-camera" />
                </div>
                <div className="ms-2"> <span>camera</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-candy" />
                </div>
                <div className="ms-2"> <span>candy</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-candy-cane" />
                </div>
                <div className="ms-2"> <span>candy-cane</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-capsule" />
                </div>
                <div className="ms-2"> <span>capsule</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-car" />
                </div>
                <div className="ms-2"> <span>car</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-car-alt" />
                </div>
                <div className="ms-2"> <span>car-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-caravan" />
                </div>
                <div className="ms-2"> <span>caravan</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cart" />
                </div>
                <div className="ms-2"> <span>cart</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cart-full" />
                </div>
                <div className="ms-2"> <span>cart-full</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-certificate" />
                </div>
                <div className="ms-2"> <span>certificate</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-checkbox" />
                </div>
                <div className="ms-2"> <span>checkbox</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-checkmark" />
                </div>
                <div className="ms-2"> <span>checkmark</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-checkmark-circle" />
                </div>
                <div className="ms-2"> <span>checkmark-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chef-hat" />
                </div>
                <div className="ms-2"> <span>chef-hat</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-down" />
                </div>
                <div className="ms-2"> <span>chevron-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-down-circle" />
                </div>
                <div className="ms-2"> <span>chevron-down-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-left" />
                </div>
                <div className="ms-2"> <span>chevron-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-left-circle" />
                </div>
                <div className="ms-2"> <span>chevron-left-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-right" />
                </div>
                <div className="ms-2"> <span>chevron-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-right-circle" />
                </div>
                <div className="ms-2"> <span>chevron-right-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-up" />
                </div>
                <div className="ms-2"> <span>chevron-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chevron-up-circle" />
                </div>
                <div className="ms-2"> <span>chevron-up-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-chrome" />
                </div>
                <div className="ms-2"> <span>chrome</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-circle-minus" />
                </div>
                <div className="ms-2"> <span>circle-minus</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-circle-plus" />
                </div>
                <div className="ms-2"> <span>circle-plus</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-clipboard" />
                </div>
                <div className="ms-2"> <span>clipboard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-close" />
                </div>
                <div className="ms-2"> <span>close</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud" />
                </div>
                <div className="ms-2"> <span>cloud</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud-check" />
                </div>
                <div className="ms-2"> <span>cloud-check</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud-download" />
                </div>
                <div className="ms-2"> <span>cloud-download</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud-network" />
                </div>
                <div className="ms-2"> <span>cloud-network</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud-sync" />
                </div>
                <div className="ms-2"> <span>cloud-sync</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloud-upload" />
                </div>
                <div className="ms-2"> <span>cloud-upload</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cloudy-sun" />
                </div>
                <div className="ms-2"> <span>cloudy-sun</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-code" />
                </div>
                <div className="ms-2"> <span>code</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-code-alt" />
                </div>
                <div className="ms-2"> <span>code-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-codepen" />
                </div>
                <div className="ms-2"> <span>codepen</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-coffee-cup" />
                </div>
                <div className="ms-2"> <span>coffee-cup</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cog" />
                </div>
                <div className="ms-2"> <span>cog</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cogs" />
                </div>
                <div className="ms-2"> <span>cogs</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-coin" />
                </div>
                <div className="ms-2"> <span>coin</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-comments" />
                </div>
                <div className="ms-2"> <span>comments</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-comments-alt" />
                </div>
                <div className="ms-2"> <span>comments-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-comments-reply" />
                </div>
                <div className="ms-2"> <span>comments-reply</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-compass" />
                </div>
                <div className="ms-2"> <span>compass</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-construction" />
                </div>
                <div className="ms-2"> <span>construction</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-construction-hammer" />
                </div>
                <div className="ms-2"> <span>construction-hammer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-consulting" />
                </div>
                <div className="ms-2"> <span>consulting</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-control-panel" />
                </div>
                <div className="ms-2"> <span>control-panel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cpanel" />
                </div>
                <div className="ms-2"> <span>cpanel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-creative-commons" />
                </div>
                <div className="ms-2"> <span>creative-commons</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-credit-cards" />
                </div>
                <div className="ms-2"> <span>credit-cards</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-crop" />
                </div>
                <div className="ms-2"> <span>crop</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cross-circle" />
                </div>
                <div className="ms-2"> <span>cross-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-crown" />
                </div>
                <div className="ms-2"> <span>crown</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-css3" />
                </div>
                <div className="ms-2"> <span>css3</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cup" />
                </div>
                <div className="ms-2"> <span>cup</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-customer" />
                </div>
                <div className="ms-2"> <span>customer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-cut" />
                </div>
                <div className="ms-2"> <span>cut</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dashboard" />
                </div>
                <div className="ms-2"> <span>dashboard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-database" />
                </div>
                <div className="ms-2"> <span>database</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-delivery" />
                </div>
                <div className="ms-2"> <span>delivery</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dev" />
                </div>
                <div className="ms-2"> <span>dev</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-diamond" />
                </div>
                <div className="ms-2"> <span>diamond</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-diamond-alt" />
                </div>
                <div className="ms-2"> <span>diamond-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-diners-club" />
                </div>
                <div className="ms-2"> <span>diners-club</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dinner" />
                </div>
                <div className="ms-2"> <span>dinner</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-direction" />
                </div>
                <div className="ms-2"> <span>direction</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-direction-alt" />
                </div>
                <div className="ms-2"> <span>direction-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-direction-ltr" />
                </div>
                <div className="ms-2"> <span>direction-ltr</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-direction-rtl" />
                </div>
                <div className="ms-2"> <span>direction-rtl</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-discord" />
                </div>
                <div className="ms-2"> <span>discord</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-discover" />
                </div>
                <div className="ms-2"> <span>discover</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-display" />
                </div>
                <div className="ms-2"> <span>display</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-display-alt" />
                </div>
                <div className="ms-2"> <span>display-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-docker" />
                </div>
                <div className="ms-2"> <span>docker</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dollar" />
                </div>
                <div className="ms-2"> <span>dollar</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-domain" />
                </div>
                <div className="ms-2"> <span>domain</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-download" />
                </div>
                <div className="ms-2"> <span>download</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dribbble" />
                </div>
                <div className="ms-2"> <span>dribbble</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-drop" />
                </div>
                <div className="ms-2"> <span>drop</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dropbox" />
                </div>
                <div className="ms-2"> <span>dropbox</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dropbox-original" />
                </div>
                <div className="ms-2"> <span>dropbox-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-drupal" />
                </div>
                <div className="ms-2"> <span>drupal</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-drupal-original" />
                </div>
                <div className="ms-2"> <span>drupal-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-dumbbell" />
                </div>
                <div className="ms-2"> <span>dumbbell</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-edge" />
                </div>
                <div className="ms-2"> <span>edge</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-cool" />
                </div>
                <div className="ms-2"> <span>emoji-cool</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-friendly" />
                </div>
                <div className="ms-2"> <span>emoji-friendly</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-happy" />
                </div>
                <div className="ms-2"> <span>emoji-happy</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-sad" />
                </div>
                <div className="ms-2"> <span>emoji-sad</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-smile" />
                </div>
                <div className="ms-2"> <span>emoji-smile</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-speechless" />
                </div>
                <div className="ms-2"> <span>emoji-speechless</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-suspect" />
                </div>
                <div className="ms-2"> <span>emoji-suspect</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-emoji-tounge" />
                </div>
                <div className="ms-2"> <span>emoji-tounge</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-empty-file" />
                </div>
                <div className="ms-2"> <span>empty-file</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-enter" />
                </div>
                <div className="ms-2"> <span>enter</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-envato" />
                </div>
                <div className="ms-2"> <span>envato</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-envelope" />
                </div>
                <div className="ms-2"> <span>envelope</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-eraser" />
                </div>
                <div className="ms-2"> <span>eraser</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-euro" />
                </div>
                <div className="ms-2"> <span>euro</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-exit" />
                </div>
                <div className="ms-2"> <span>exit</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-exit-down" />
                </div>
                <div className="ms-2"> <span>exit-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-exit-up" />
                </div>
                <div className="ms-2"> <span>exit-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-eye" />
                </div>
                <div className="ms-2"> <span>eye</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-facebook" />
                </div>
                <div className="ms-2"> <span>facebook</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-facebook-filled" />
                </div>
                <div className="ms-2"> <span>facebook-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-facebook-messenger" />
                </div>
                <div className="ms-2"> <span>facebook-messenger</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-facebook-original" />
                </div>
                <div className="ms-2"> <span>facebook-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-facebook-oval" />
                </div>
                <div className="ms-2"> <span>facebook-oval</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-figma" />
                </div>
                <div className="ms-2"> <span>figma</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-files" />
                </div>
                <div className="ms-2"> <span>files</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-firefox" />
                </div>
                <div className="ms-2"> <span>firefox</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-firefox-original" />
                </div>
                <div className="ms-2"> <span>firefox-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-fireworks" />
                </div>
                <div className="ms-2"> <span>fireworks</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-first-aid" />
                </div>
                <div className="ms-2"> <span>first-aid</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-flag" />
                </div>
                <div className="ms-2"> <span>flag</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-flag-alt" />
                </div>
                <div className="ms-2"> <span>flag-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-flags" />
                </div>
                <div className="ms-2"> <span>flags</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-flickr" />
                </div>
                <div className="ms-2"> <span>flickr</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-flower" />
                </div>
                <div className="ms-2"> <span>flower</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-folder" />
                </div>
                <div className="ms-2"> <span>folder</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-forward" />
                </div>
                <div className="ms-2"> <span>forward</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-frame-expand" />
                </div>
                <div className="ms-2"> <span>frame-expand</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-fresh-juice" />
                </div>
                <div className="ms-2"> <span>fresh-juice</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-full-screen" />
                </div>
                <div className="ms-2"> <span>full-screen</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-funnel" />
                </div>
                <div className="ms-2"> <span>funnel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-gallery" />
                </div>
                <div className="ms-2"> <span>gallery</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-game" />
                </div>
                <div className="ms-2"> <span>game</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-gift" />
                </div>
                <div className="ms-2"> <span>gift</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-git" />
                </div>
                <div className="ms-2"> <span>git</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-github" />
                </div>
                <div className="ms-2"> <span>github</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-github-original" />
                </div>
                <div className="ms-2"> <span>github-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-goodreads" />
                </div>
                <div className="ms-2"> <span>goodreads</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-google" />
                </div>
                <div className="ms-2"> <span>google</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-google-drive" />
                </div>
                <div className="ms-2"> <span>google-drive</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-google-pay" />
                </div>
                <div className="ms-2"> <span>google-pay</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-google-wallet" />
                </div>
                <div className="ms-2"> <span>google-wallet</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-graduation" />
                </div>
                <div className="ms-2"> <span>graduation</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-graph" />
                </div>
                <div className="ms-2"> <span>graph</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-grid" />
                </div>
                <div className="ms-2"> <span>grid</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-grid-alt" />
                </div>
                <div className="ms-2"> <span>grid-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-grow" />
                </div>
                <div className="ms-2"> <span>grow</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-hacker-news" />
                </div>
                <div className="ms-2"> <span>hacker-news</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-hammer" />
                </div>
                <div className="ms-2"> <span>hammer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-hand" />
                </div>
                <div className="ms-2"> <span>hand</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-handshake" />
                </div>
                <div className="ms-2"> <span>handshake</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-harddrive" />
                </div>
                <div className="ms-2"> <span>harddrive</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-headphone" />
                </div>
                <div className="ms-2"> <span>headphone</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-headphone-alt" />
                </div>
                <div className="ms-2"> <span>headphone-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-heart" />
                </div>
                <div className="ms-2"> <span>heart</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-heart-filled" />
                </div>
                <div className="ms-2"> <span>heart-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-heart-monitor" />
                </div>
                <div className="ms-2"> <span>heart-monitor</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-helicopter" />
                </div>
                <div className="ms-2"> <span>helicopter</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-helmet" />
                </div>
                <div className="ms-2"> <span>helmet</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-help" />
                </div>
                <div className="ms-2"> <span>help</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-highlight" />
                </div>
                <div className="ms-2"> <span>highlight</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-highlight-alt" />
                </div>
                <div className="ms-2"> <span>highlight-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-home" />
                </div>
                <div className="ms-2"> <span>home</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-hospital" />
                </div>
                <div className="ms-2"> <span>hospital</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-hourglass" />
                </div>
                <div className="ms-2"> <span>hourglass</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-html5" />
                </div>
                <div className="ms-2"> <span>html5</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-image" />
                </div>
                <div className="ms-2"> <span>image</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-inbox" />
                </div>
                <div className="ms-2"> <span>inbox</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-indent-decrease" />
                </div>
                <div className="ms-2"> <span>indent-decrease</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-indent-increase" />
                </div>
                <div className="ms-2"> <span>indent-increase</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-infinite" />
                </div>
                <div className="ms-2"> <span>infinite</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-information" />
                </div>
                <div className="ms-2"> <span>information</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-instagram" />
                </div>
                <div className="ms-2"> <span>instagram</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-instagram-filled" />
                </div>
                <div className="ms-2"> <span>instagram-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-instagram-original" />
                </div>
                <div className="ms-2"> <span>instagram-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-invention" />
                </div>
                <div className="ms-2"> <span>invention</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-invest-monitor" />
                </div>
                <div className="ms-2"> <span>invest-monitor</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-investment" />
                </div>
                <div className="ms-2"> <span>investment</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-island" />
                </div>
                <div className="ms-2"> <span>island</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-italic" />
                </div>
                <div className="ms-2"> <span>italic</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-java" />
                </div>
                <div className="ms-2"> <span>java</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-javascript" />
                </div>
                <div className="ms-2"> <span>javascript</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-jcb" />
                </div>
                <div className="ms-2"> <span>jcb</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-joomla" />
                </div>
                <div className="ms-2"> <span>joomla</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-joomla-original" />
                </div>
                <div className="ms-2"> <span>joomla-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-jsfiddle" />
                </div>
                <div className="ms-2"> <span>jsfiddle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-juice" />
                </div>
                <div className="ms-2"> <span>juice</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-key" />
                </div>
                <div className="ms-2"> <span>key</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-keyboard" />
                </div>
                <div className="ms-2"> <span>keyboard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-keyword-research" />
                </div>
                <div className="ms-2"> <span>keyword-research</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-laptop" />
                </div>
                <div className="ms-2"> <span>laptop</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-laptop-phone" />
                </div>
                <div className="ms-2"> <span>laptop-phone</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-laravel" />
                </div>
                <div className="ms-2"> <span>laravel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-layers" />
                </div>
                <div className="ms-2"> <span>layers</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-layout" />
                </div>
                <div className="ms-2"> <span>layout</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-leaf" />
                </div>
                <div className="ms-2"> <span>leaf</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-library" />
                </div>
                <div className="ms-2"> <span>library</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-life-ring" />
                </div>
                <div className="ms-2"> <span>life-ring</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-line" />
                </div>
                <div className="ms-2"> <span>line</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-line-dashed" />
                </div>
                <div className="ms-2"> <span>line-dashed</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-line-dotted" />
                </div>
                <div className="ms-2"> <span>line-dotted</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-line-double" />
                </div>
                <div className="ms-2"> <span>line-double</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-line-spacing" />
                </div>
                <div className="ms-2"> <span>line-spacing</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-lineicons" />
                </div>
                <div className="ms-2"> <span>lineicons</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-lineicons-alt" />
                </div>
                <div className="ms-2"> <span>lineicons-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-link" />
                </div>
                <div className="ms-2"> <span>link</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-linkedin" />
                </div>
                <div className="ms-2"> <span>linkedin</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-linkedin-original" />
                </div>
                <div className="ms-2"> <span>linkedin-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-list" />
                </div>
                <div className="ms-2"> <span>list</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-lock" />
                </div>
                <div className="ms-2"> <span>lock</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-lock-alt" />
                </div>
                <div className="ms-2"> <span>lock-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-magnet" />
                </div>
                <div className="ms-2"> <span>magnet</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-magnifier" />
                </div>
                <div className="ms-2"> <span>magnifier</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mailchimp" />
                </div>
                <div className="ms-2"> <span>mailchimp</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-map" />
                </div>
                <div className="ms-2"> <span>map</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-map-marker" />
                </div>
                <div className="ms-2"> <span>map-marker</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mashroom" />
                </div>
                <div className="ms-2"> <span>mashroom</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mastercard" />
                </div>
                <div className="ms-2"> <span>mastercard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-medium" />
                </div>
                <div className="ms-2"> <span>medium</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-megento" />
                </div>
                <div className="ms-2"> <span>megento</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-menu" />
                </div>
                <div className="ms-2"> <span>menu</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mic" />
                </div>
                <div className="ms-2"> <span>mic</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-microphone" />
                </div>
                <div className="ms-2"> <span>microphone</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-microscope" />
                </div>
                <div className="ms-2"> <span>microscope</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-microsoft" />
                </div>
                <div className="ms-2"> <span>microsoft</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-minus" />
                </div>
                <div className="ms-2"> <span>minus</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mobile" />
                </div>
                <div className="ms-2"> <span>mobile</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-money-location" />
                </div>
                <div className="ms-2"> <span>money-location</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-money-protection" />
                </div>
                <div className="ms-2"> <span>money-protection</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-more" />
                </div>
                <div className="ms-2"> <span>more</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-more-alt" />
                </div>
                <div className="ms-2"> <span>more-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-mouse" />
                </div>
                <div className="ms-2"> <span>mouse</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-move" />
                </div>
                <div className="ms-2"> <span>move</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-music" />
                </div>
                <div className="ms-2"> <span>music</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-network" />
                </div>
                <div className="ms-2"> <span>network</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-night" />
                </div>
                <div className="ms-2"> <span>night</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-nodejs" />
                </div>
                <div className="ms-2"> <span>nodejs</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-nodejs-alt" />
                </div>
                <div className="ms-2"> <span>nodejs-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-notepad" />
                </div>
                <div className="ms-2"> <span>notepad</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-npm" />
                </div>
                <div className="ms-2"> <span>npm</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-offer" />
                </div>
                <div className="ms-2"> <span>offer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-opera" />
                </div>
                <div className="ms-2"> <span>opera</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-package" />
                </div>
                <div className="ms-2"> <span>package</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-page-break" />
                </div>
                <div className="ms-2"> <span>page-break</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pagination" />
                </div>
                <div className="ms-2"> <span>pagination</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-paint-bucket" />
                </div>
                <div className="ms-2"> <span>paint-bucket</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-paint-roller" />
                </div>
                <div className="ms-2"> <span>paint-roller</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pallet" />
                </div>
                <div className="ms-2"> <span>pallet</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-paperclip" />
                </div>
                <div className="ms-2"> <span>paperclip</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-patreon" />
                </div>
                <div className="ms-2"> <span>patreon</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pause" />
                </div>
                <div className="ms-2"> <span>pause</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-paypal" />
                </div>
                <div className="ms-2"> <span>paypal</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-paypal-original" />
                </div>
                <div className="ms-2"> <span>paypal-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pencil" />
                </div>
                <div className="ms-2"> <span>pencil</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pencil-alt" />
                </div>
                <div className="ms-2"> <span>pencil-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-phone" />
                </div>
                <div className="ms-2"> <span>phone</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-phone-set" />
                </div>
                <div className="ms-2"> <span>phone-set</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-php" />
                </div>
                <div className="ms-2"> <span>php</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pie-chart" />
                </div>
                <div className="ms-2"> <span>pie-chart</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pilcrow" />
                </div>
                <div className="ms-2"> <span>pilcrow</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pin" />
                </div>
                <div className="ms-2"> <span>pin</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pinterest" />
                </div>
                <div className="ms-2"> <span>pinterest</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pizza" />
                </div>
                <div className="ms-2"> <span>pizza</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-plane" />
                </div>
                <div className="ms-2"> <span>plane</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-play" />
                </div>
                <div className="ms-2"> <span>play</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-play-store" />
                </div>
                <div className="ms-2"> <span>play-store</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-plug" />
                </div>
                <div className="ms-2"> <span>plug</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-plus" />
                </div>
                <div className="ms-2"> <span>plus</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pointer" />
                </div>
                <div className="ms-2"> <span>pointer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pointer-down" />
                </div>
                <div className="ms-2"> <span>pointer-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pointer-left" />
                </div>
                <div className="ms-2"> <span>pointer-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pointer-right" />
                </div>
                <div className="ms-2"> <span>pointer-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pointer-up" />
                </div>
                <div className="ms-2"> <span>pointer-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-popup" />
                </div>
                <div className="ms-2"> <span>popup</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-postcard" />
                </div>
                <div className="ms-2"> <span>postcard</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pound" />
                </div>
                <div className="ms-2"> <span>pound</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-power-switch" />
                </div>
                <div className="ms-2"> <span>power-switch</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-printer" />
                </div>
                <div className="ms-2"> <span>printer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-producthunt" />
                </div>
                <div className="ms-2"> <span>producthunt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-protection" />
                </div>
                <div className="ms-2"> <span>protection</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pulse" />
                </div>
                <div className="ms-2"> <span>pulse</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-pyramids" />
                </div>
                <div className="ms-2"> <span>pyramids</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-python" />
                </div>
                <div className="ms-2"> <span>python</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-question-circle" />
                </div>
                <div className="ms-2"> <span>question-circle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-quora" />
                </div>
                <div className="ms-2"> <span>quora</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-quotation" />
                </div>
                <div className="ms-2"> <span>quotation</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-radio-button" />
                </div>
                <div className="ms-2"> <span>radio-button</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-rain" />
                </div>
                <div className="ms-2"> <span>rain</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-react" />
                </div>
                <div className="ms-2"> <span>react</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-reddit" />
                </div>
                <div className="ms-2"> <span>reddit</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-reload" />
                </div>
                <div className="ms-2"> <span>reload</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-remove-file" />
                </div>
                <div className="ms-2"> <span>remove-file</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-reply" />
                </div>
                <div className="ms-2"> <span>reply</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-restaurant" />
                </div>
                <div className="ms-2"> <span>restaurant</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-revenue" />
                </div>
                <div className="ms-2"> <span>revenue</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-road" />
                </div>
                <div className="ms-2"> <span>road</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-rocket" />
                </div>
                <div className="ms-2"> <span>rocket</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-rss-feed" />
                </div>
                <div className="ms-2"> <span>rss-feed</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ruler" />
                </div>
                <div className="ms-2"> <span>ruler</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ruler-alt" />
                </div>
                <div className="ms-2"> <span>ruler-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ruler-pencil" />
                </div>
                <div className="ms-2"> <span>ruler-pencil</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-rupee" />
                </div>
                <div className="ms-2"> <span>rupee</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-save" />
                </div>
                <div className="ms-2"> <span>save</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-school-bench" />
                </div>
                <div className="ms-2"> <span>school-bench</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-school-bench-alt" />
                </div>
                <div className="ms-2"> <span>school-bench-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-scooter" />
                </div>
                <div className="ms-2"> <span>scooter</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-scroll-down" />
                </div>
                <div className="ms-2"> <span>scroll-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-search" />
                </div>
                <div className="ms-2"> <span>search</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-search-alt" />
                </div>
                <div className="ms-2"> <span>search-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-select" />
                </div>
                <div className="ms-2"> <span>select</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-seo" />
                </div>
                <div className="ms-2"> <span>seo</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-service" />
                </div>
                <div className="ms-2"> <span>service</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-share" />
                </div>
                <div className="ms-2"> <span>share</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-share-alt" />
                </div>
                <div className="ms-2"> <span>share-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shield" />
                </div>
                <div className="ms-2"> <span>shield</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shift-left" />
                </div>
                <div className="ms-2"> <span>shift-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shift-right" />
                </div>
                <div className="ms-2"> <span>shift-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ship" />
                </div>
                <div className="ms-2"> <span>ship</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shopify" />
                </div>
                <div className="ms-2"> <span>shopify</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shopping-basket" />
                </div>
                <div className="ms-2"> <span>shopping-basket</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shortcode" />
                </div>
                <div className="ms-2"> <span>shortcode</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shovel" />
                </div>
                <div className="ms-2"> <span>shovel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-shuffle" />
                </div>
                <div className="ms-2"> <span>shuffle</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-signal" />
                </div>
                <div className="ms-2"> <span>signal</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sketch" />
                </div>
                <div className="ms-2"> <span>sketch</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-skipping-rope" />
                </div>
                <div className="ms-2"> <span>skipping-rope</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-skype" />
                </div>
                <div className="ms-2"> <span>skype</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-slack" />
                </div>
                <div className="ms-2"> <span>slack</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-slice" />
                </div>
                <div className="ms-2"> <span>slice</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-slideshare" />
                </div>
                <div className="ms-2"> <span>slideshare</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-slim" />
                </div>
                <div className="ms-2"> <span>slim</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-snapchat" />
                </div>
                <div className="ms-2"> <span>snapchat</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sort-alpha-asc" />
                </div>
                <div className="ms-2"> <span>sort-alpha-asc</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sort-amount-asc" />
                </div>
                <div className="ms-2"> <span>sort-amount-asc</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sort-amount-dsc" />
                </div>
                <div className="ms-2"> <span>sort-amount-dsc</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-souncloud-original" />
                </div>
                <div className="ms-2"> <span>souncloud-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-soundcloud" />
                </div>
                <div className="ms-2"> <span>soundcloud</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spellcheck" />
                </div>
                <div className="ms-2"> <span>spellcheck</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spiner-solid" />
                </div>
                <div className="ms-2"> <span>spiner-solid</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spinner" />
                </div>
                <div className="ms-2"> <span>spinner</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spinner-arrow" />
                </div>
                <div className="ms-2"> <span>spinner-arrow</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spotify" />
                </div>
                <div className="ms-2"> <span>spotify</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spotify-original" />
                </div>
                <div className="ms-2"> <span>spotify-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-spray" />
                </div>
                <div className="ms-2"> <span>spray</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sprout" />
                </div>
                <div className="ms-2"> <span>sprout</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stackoverflow" />
                </div>
                <div className="ms-2"> <span>stackoverflow</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stamp" />
                </div>
                <div className="ms-2"> <span>stamp</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-star" />
                </div>
                <div className="ms-2"> <span>star</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-star-empty" />
                </div>
                <div className="ms-2"> <span>star-empty</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-star-filled" />
                </div>
                <div className="ms-2"> <span>star-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-star-half" />
                </div>
                <div className="ms-2"> <span>star-half</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stats-down" />
                </div>
                <div className="ms-2"> <span>stats-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stats-up" />
                </div>
                <div className="ms-2"> <span>stats-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-steam" />
                </div>
                <div className="ms-2"> <span>steam</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sthethoscope" />
                </div>
                <div className="ms-2"> <span>sthethoscope</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stop" />
                </div>
                <div className="ms-2"> <span>stop</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-strikethrough" />
                </div>
                <div className="ms-2"> <span>strikethrough</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stripe" />
                </div>
                <div className="ms-2"> <span>stripe</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-stumbleupon" />
                </div>
                <div className="ms-2"> <span>stumbleupon</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-sun" />
                </div>
                <div className="ms-2"> <span>sun</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-support" />
                </div>
                <div className="ms-2"> <span>support</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-surf-board" />
                </div>
                <div className="ms-2"> <span>surf-board</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-swift" />
                </div>
                <div className="ms-2"> <span>swift</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-syringe" />
                </div>
                <div className="ms-2"> <span>syringe</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-tab" />
                </div>
                <div className="ms-2"> <span>tab</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-tag" />
                </div>
                <div className="ms-2"> <span>tag</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-target" />
                </div>
                <div className="ms-2"> <span>target</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-target-customer" />
                </div>
                <div className="ms-2"> <span>target-customer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-target-revenue" />
                </div>
                <div className="ms-2"> <span>target-revenue</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-taxi" />
                </div>
                <div className="ms-2"> <span>taxi</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-teabag" />
                </div>
                <div className="ms-2"> <span>teabag</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-telegram" />
                </div>
                <div className="ms-2"> <span>telegram</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-telegram-original" />
                </div>
                <div className="ms-2"> <span>telegram-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-align-center" />
                </div>
                <div className="ms-2"> <span>text-align-center</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-align-justify" />
                </div>
                <div className="ms-2"> <span>text-align-justify</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-align-left" />
                </div>
                <div className="ms-2"> <span>text-align-left</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-align-right" />
                </div>
                <div className="ms-2"> <span>text-align-right</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-format" />
                </div>
                <div className="ms-2"> <span>text-format</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-text-format-remove" />
                </div>
                <div className="ms-2"> <span>text-format-remove</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-thought" />
                </div>
                <div className="ms-2"> <span>thought</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-thumbs-down" />
                </div>
                <div className="ms-2"> <span>thumbs-down</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-thumbs-up" />
                </div>
                <div className="ms-2"> <span>thumbs-up</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-thunder" />
                </div>
                <div className="ms-2"> <span>thunder</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-thunder-alt" />
                </div>
                <div className="ms-2"> <span>thunder-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ticket" />
                </div>
                <div className="ms-2"> <span>ticket</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ticket-alt" />
                </div>
                <div className="ms-2"> <span>ticket-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-timer" />
                </div>
                <div className="ms-2"> <span>timer</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-train" />
                </div>
                <div className="ms-2"> <span>train</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-train-alt" />
                </div>
                <div className="ms-2"> <span>train-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-trash" />
                </div>
                <div className="ms-2"> <span>trash</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-travel" />
                </div>
                <div className="ms-2"> <span>travel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-tree" />
                </div>
                <div className="ms-2"> <span>tree</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-trees" />
                </div>
                <div className="ms-2"> <span>trees</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-trello" />
                </div>
                <div className="ms-2"> <span>trello</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-trowel" />
                </div>
                <div className="ms-2"> <span>trowel</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-tshirt" />
                </div>
                <div className="ms-2"> <span>tshirt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-tumblr" />
                </div>
                <div className="ms-2"> <span>tumblr</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-twitch" />
                </div>
                <div className="ms-2"> <span>twitch</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-twitter" />
                </div>
                <div className="ms-2"> <span>twitter</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-twitter-filled" />
                </div>
                <div className="ms-2"> <span>twitter-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-twitter-original" />
                </div>
                <div className="ms-2"> <span>twitter-original</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ubuntu" />
                </div>
                <div className="ms-2"> <span>ubuntu</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-underline" />
                </div>
                <div className="ms-2"> <span>underline</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-unlink" />
                </div>
                <div className="ms-2"> <span>unlink</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-unlock" />
                </div>
                <div className="ms-2"> <span>unlock</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-upload" />
                </div>
                <div className="ms-2"> <span>upload</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-user" />
                </div>
                <div className="ms-2"> <span>user</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-users" />
                </div>
                <div className="ms-2"> <span>users</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ux" />
                </div>
                <div className="ms-2"> <span>ux</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-vector" />
                </div>
                <div className="ms-2"> <span>vector</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-video" />
                </div>
                <div className="ms-2"> <span>video</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-vimeo" />
                </div>
                <div className="ms-2"> <span>vimeo</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-visa" />
                </div>
                <div className="ms-2"> <span>visa</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-vk" />
                </div>
                <div className="ms-2"> <span>vk</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-volume" />
                </div>
                <div className="ms-2"> <span>volume</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-volume-high" />
                </div>
                <div className="ms-2"> <span>volume-high</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-volume-low" />
                </div>
                <div className="ms-2"> <span>volume-low</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-volume-medium" />
                </div>
                <div className="ms-2"> <span>volume-medium</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-volume-mute" />
                </div>
                <div className="ms-2"> <span>volume-mute</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wallet" />
                </div>
                <div className="ms-2"> <span>wallet</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-warning" />
                </div>
                <div className="ms-2"> <span>warning</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-website" />
                </div>
                <div className="ms-2"> <span>website</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-website-alt" />
                </div>
                <div className="ms-2"> <span>website-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wechat" />
                </div>
                <div className="ms-2"> <span>wechat</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-weight" />
                </div>
                <div className="ms-2"> <span>weight</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-whatsapp" />
                </div>
                <div className="ms-2"> <span>whatsapp</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wheelbarrow" />
                </div>
                <div className="ms-2"> <span>wheelbarrow</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wheelchair" />
                </div>
                <div className="ms-2"> <span>wheelchair</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-windows" />
                </div>
                <div className="ms-2"> <span>windows</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wordpress" />
                </div>
                <div className="ms-2"> <span>wordpress</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-wordpress-filled" />
                </div>
                <div className="ms-2"> <span>wordpress-filled</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-world" />
                </div>
                <div className="ms-2"> <span>world</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-world-alt" />
                </div>
                <div className="ms-2"> <span>world-alt</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-write" />
                </div>
                <div className="ms-2"> <span>write</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-yahoo" />
                </div>
                <div className="ms-2"> <span>yahoo</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-ycombinator" />
                </div>
                <div className="ms-2"> <span>ycombinator</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-yen" />
                </div>
                <div className="ms-2"> <span>yen</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-youtube" />
                </div>
                <div className="ms-2"> <span>youtube</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-zip" />
                </div>
                <div className="ms-2"> <span>zip</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-zoom-in" />
                </div>
                <div className="ms-2"> <span>zoom-in</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center theme-icons shadow-sm p-2 cursor-pointer rounded">
                <div className="font-22 text-primary"> <i className="lni lni-zoom-out" />
                </div>
                <div className="ms-2"> <span>zoom-out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*end page-content-wrapper*/}
</div>

  )
}

export default Lineicons
